.navbar {
    transition: all 0.3s ease-in-out;
  }
  
  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #000;
    
  }
  
  .right {
    margin-left: auto !important; /* Navbar'ı sağa itmek için */
  }