body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./img.svg');
  background-color: rgba(20, 20, 30);
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  background-color: rgba(30, 30, 30, 0.9) !important;
}

a.nav-link {
  color: #D0D0D0 !important;
  font-weight: bold;
}

button.custom-button.btn.btn-link {
  font-size: 40px !important;
  color: #00BFFF !important;
  margin-top: -15px !important;
}

h1 {
  color: #D0D0D0 !important;
  text-shadow: 2px 2px 4px rgba(30, 30, 30, 0.7);
}

.custom-text {
  color: #D0D0D0;
  align-items: center;
}

.btn-primary {
  background-color: #D0D0D0 !important;
  color: #222222 !important;
  border: none !important;
  font-weight: bold;
}

.features {
  margin-top: 100px;
}

.transparent-card {
  background-color: rgba(30, 30, 30, 0.15) !important;
  border: 1px solid rgba(30, 30, 30, 0.3) !important;
  cursor: pointer;
}

.transparent-card:hover {
  background-color: rgba(30, 30, 30, 0.25) !important;
  border-color: #D0D0D0 !important;
}

h5 {
  font-weight: 700 !important;
  font-size: 36px !important;
  color: #D0D0D0;
}

.subtitle {
  color: #A0A0A0 !important;
}

.header-item {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 5px;
}

header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgba(30, 30, 30, 0.9);
}

.header-text {
  color: #D0D0D0;
}

.header-item button {
  color: #D0D0D0;
  border-color: #D0D0D0;
  background-color: transparent;
}

.header-item button:hover {
  background-color: rgba(30, 30, 30, 0.3);
}

@media (max-width: 768px) {
  .watch {
    padding: 100px 75px;
  }
}

@media (max-width: 576px) {
  .watch {
    padding: 50px 25px;
  }
}

.btn-secondary {
  background-color: #6A5ACD !important;
  color: #D0D0D0 !important;
  border: none !important;
}

ul {
  list-style-type: none;
}

.world-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button#dropdown-basic {
  background-color: transparent !important;
  border-color: #D0D0D0 !important;
}

a.btn.btn-link {
  color: #D0D0D0 !important;
  text-decoration: none !important;
}

a.mx-2.btn.btn-outline-light {
  background: linear-gradient(89.09deg, #00BFFF -39.58%, #4682B4 37.58%, #6A5ACD 75.08%, #FF69B4 101.65%) !important;
  border: 1px solid #D0D0D0;
  margin-top: 5px;
  margin-bottom: 5px;
}

button.btn.btn-link {
  color: #D0D0D0 !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

input.form-control-home {
  border: 1px solid #6A5ACD;
  background: rgba(30, 30, 30, 0.25);
  border-radius: 8px;
  color: #D0D0D0;
}